function completedRequest(nextUrl) {
  // OCR解析状況の取得
  $.ajax({
    type: "GET",
    url: "./get_status",
  }).done((data) => {
    console.log(data);
    if (data["completed"] === true) {
      setTimeout(() => $("#complete").click(), 2000);
      setTimeout(() => {
        location.href = nextUrl;
      }, 1000);
    }
  });
}

$(() => {
  if ($("#financial_statement-reading_pdf").length) {
    setInterval(() => {
      completedRequest($("#next_url").data("url"));
    }, 5000);
  }
});
