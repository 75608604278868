import React from "react";
import { hot } from "react-hot-loader";
import { interviewAnswerEdit } from "./interviewAnswers";
import {
  ANSWER_TYPE__CHECK_BOX,
  ANSWER_TYPE__RADIO_BUTTON,
  ANSWER_TYPE__SELECT_BOX,
  ANSWER_TYPE__TABLE,
  ANSWER_TYPE__TEXT_BOX,
  ANSWER_TYPE__FILE,
  DEVELOPMENT,
} from "../../constants";
import { tableRowWithLen } from "./interviewAnswers/elements";
import autosize from "autosize";


class InterviewBackgroundContainer extends React.Component {
  constructor(props) {
    super(props);
    const { pageName } = props;
    if (process.env.NODE_ENV === DEVELOPMENT) {
      console.log(`pageName: ${pageName}`);
    }

    switch (pageName) {
      case "reports__interviews":
        // インタビュー一覧
        this.interviews();
        break;
      case "interview_answers__edit":
        // インタビュー回答画面
        interviewAnswerEdit();
        break;
      case "interview_answers__result_update":
      case "interview_answers__result_edit":
        // インタビュー更新
        this.interviewAnswerResultUpdate();
        break;
      case "interview_questions__updates":
      case "interview_questions__edit":
        // 管理画面インタビュー編集画面
        this.interviewQuestionEdit();
        break;
      default:
        break;
    }
  }

  /**
   * インタビューシート一覧
   */
  interviews() {
    $(document).on("click", "#documents-index #trigger--download_submit", () =>
      setTimeout(() => {
        const $submit = $("#documents-index #trigger--download_submit");
        if ($submit.is(":disabled")) {
          submit.prop("disabled", false);
        }
      }, 1000)
    );

    let check = 0;
    $(document).on("click", "#documents-index #trigger--all_check", () => {
      if (check === 0) {
        $("#documents-index .list-group-item input").prop("checked", true);
        check = 1;
      } else {
        $("#documents-index .list-group-item input").prop("checked", false);
        check = 0;
      }
    });

    $(document).on(
      "click",
      "#documents-index .trigger--url_issuance",
      function () {
        $(this).css("pointer-events", "none");
        setTimeout(() => location.reload(), 500);
      }
    );

    $("#re_answer_request").on("click", (event) => {
      const mail_target_interview_ids = $('input[name="mail_target_interview_ids[]"]');
      let target_interview_ids = [];
      const re_answer_request_target = $('#re_answer_request_target');
      re_answer_request_target.empty();

      mail_target_interview_ids.each((i, interview) => {
        const checked = $(interview).prop("checked");
        if (checked === true) {
          const title = $(interview).data("interview_title");
          $("<p>" + title + "</p>").appendTo(re_answer_request_target);
        }
      })
    });

    var remodal = $('[data-remodal-id=modal_re_answer_request]').remodal();
    $(document).on('confirmation', remodal, (event) => {
      const element = $(event.target);
      const report_id = element.data("report_id");

      const mail_target_interview_ids = $('input[name="mail_target_interview_ids[]"]');
      let target_interview_ids = [];
      mail_target_interview_ids.each((i, interview) => {
        const checked = $(interview).prop("checked");
        if (checked === true) {
          target_interview_ids.push($(interview).val());
        }
      })

      $.ajax({
        type: "POST",
        url: `/reports/${report_id}/re_answer_request`,
        data: {
          target_interview_ids
        },
        dataType: "json",
        success: (_response) => console.log("success"),
        error: (_response) => console.log("error"),
      });
    });
  }

  /**
   * インタビュー更新
   */
  interviewAnswerResultUpdate() {
    this.setViewAnswerEdit();
    /**
     * 初期表示QA以外を隠す
     * @param {*} chain_questions_name 
     */
    const hide_all_next_question = (chain_questions_name) => {
      const chain_questions = $(chain_questions_name);
      chain_questions.each((index, chain_question) => {

        const base_question_name = $(chain_question).data("base_question_name");
        const same_base_questions = $(".base_question_" + base_question_name);
        same_base_questions.hide();
        same_base_questions.each((index, question) => {
          const radio_box = $(question).find(".form-item-radio-box");
          if (typeof radio_box !== "undefined"){
            const base_question_value_name = radio_box.data("base_question_value_name")
            if (typeof base_question_value_name !== "undefined"){
              const next_question = ".base_question_" + base_question_value_name;
              hide_all_next_question(next_question);
            }
          }
        })
      })
    }

    const display_next_question = (next_question_value_name) => {
      const next_question = ".question_box_" + next_question_value_name;
      const next_question_box = $(next_question);

      const radio_box = next_question_box.find(".form-item-radio-box");
      if(radio_box.prop('checked')){
        const value_name = radio_box.data("next_question_value_name");
        display_next_question(value_name);
      }
      next_question_box.fadeIn("fast").effect("highlight", { color: "#ffd769" }, 1200);


      
      const chain_next_question = next_question_box.data("next_question_value_name");
      if ((typeof chain_next_question !== "undefined") && chain_next_question != "") {
        display_next_question(chain_next_question);
      }
    }

    /**
     * clickイベントを追加(ラジオボタンの初期値挿入前にイベントを設定する必要があるためsetAnswer()より手前に定義)
     * ラジオボタン選択時質問詳細表示/非表示切替
     */
    $(".form-item-radio-box").on("click", (event) => {
      const element = $(event.currentTarget);
      const next_question_value_name = element.data("next_question_value_name");
      const base_question_value_name = element.data("base_question_value_name");
      if (typeof base_question_value_name !== "undefined"){
        const sibling_question = ".base_question_" + base_question_value_name;
        hide_all_next_question(sibling_question);
      }
      display_next_question(next_question_value_name);
    });

    $(".form-item-radio-box").each((index, radio_box) =>{
      const checked = $(radio_box).prop("checked");
      if (checked === true) {
        $(radio_box).click();
      }
    })


    // 行の追加を行う
    $(document).on("click", ".trigger--add_row", (event) => {
      const element = $(event.target);
      const value_name = $(element).data("value_name");
      const len =
        $("#interview_answer_edit .answer-block:visible tbody").children()
          .length + 1;
      const items = $(element).data("items");
      $(`#${value_name}`).find("table").children("tbody").append(
        "<tr/>"
      );
      $(items).each((i, item) => {
        const element = $(
          tableRowWithLen({
            valueName: value_name,
            items: items,
            i,
            len,
          })
        );
        $(`#${value_name}`).find("table").children("tbody").find("tr:last").append(element);
        setTimeout(() => {
          autosize(element.find(".form-item-input"));
        }, 0);
      })
    });

    // テーブル行削除
    $(document).on("click", ".trigger--remove_row", (event) => {
      const element = $(event.target);
      const value_name = $(element).data("value_name");
      const len = $(`#${value_name}`).find("table").children("tbody").children().length;
      if (len > 1) {
        const rt = confirm("最後の行を削除してよろしいですか？");
        if (rt === true) {
          $(`#${value_name}`).find("table").children("tbody").find("tr:last").remove();
        }
      }
    });  

    $(document).on("click", "#trigger--answer_edit_button", () =>
      this.setViewAnswerEdit()
    );

    $(document).on("click", "#trigger--answer_result_button", () =>
      this.setViewAnswerResult()
    );

    /**
     * ファイルアップロード情報更新
     */
    let displayUploadFiles = (upload_content, report_id, interview_question_name) => {
      $.ajax({
        type: "POST",
        dataType: "json",
        url: `/report_files/interview_question_files/`,
        data: {
          report_id,
          interview_question_name,
        },
        headers: {
          'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
        },
        context: upload_content,
      }).done((data, textStatus, jqXHR) => {
        const $question = $(upload_content);
        const value_name = $question.data("value_name");
        const element = $(".question_" + value_name + "_upload_files");
        element.empty();
        const upload_status_table = $('<table>').addClass('table table-sm bg-white question_upload_file_results mb-2');
        $('<thead><tr><th>ファイル名</th><th>アップロード日</th><th>ステータス</th><th></th></tr></thead>').appendTo(upload_status_table);
        const upload_status_tbody = $('<tbody>').appendTo(upload_status_table);
        data.files.forEach((file, index) => {
          let tr_tag = $('<tr class="' + value_name + '__' + file[0] + '">').appendTo(upload_status_tbody);
          $('<td>' + file[1] + '</td>').appendTo(tr_tag);
          $('<td>' + file[3].substr(0, 4) + '年' + file[3].substr(5, 2) + '年' + file[3].substr(8, 2) + '日' + '</td>').appendTo(tr_tag);
          let input_tag = $('<td><div class="upload_result success"><div class="cricle"><i class="fa fa-check fs-16 success-icon"></i></div><span style="color: #ffffff;">アップロード成功</span><input class="form-item-file-upload" type="hidden" name="' + value_name + '_' + index + '" id="' + value_name + '_' + index + '"></td></div>').appendTo(tr_tag);
          input_tag.val(file[0]);
          let td_tag = $('<td>');
          let delete_button = $('<a class="upload_file_delete btn btn-danger" data-upload_file_value_name="' + value_name + '" data-file_id="' + file[0] + '" data-file_path="' + file[1] + '">削除</div>').appendTo(td_tag);
          delete_button.on('click', function(element){
            const file_id = $(this).data('file_id');
            const file_path = $(this).data('file_path');
            $.ajax({
              type: "POST",
              url: `/user_files/${file_id}/${file_path}`,
              data: {
                "id": file_id,
                "file_path": file_path
              },
              context: this,
              dataType: "json",
              error: () => console.log("error"),
            }).done((data, textStatus, jqXHR) => {
              const upload_file_value_name = $(this).data('upload_file_value_name');
              const file_id = $(this).data('file_id');
              $('.' + upload_file_value_name + '__' + file_id).remove();
              console.log("削除！！");
            });
          })
          $(delete_button).appendTo(tr_tag);
        })
        upload_status_table.appendTo(element);
      });    
    }

    /**
     * Fileアップロード
     */
    const file_containers = $(".user_files__upload_interview_file_container");
    file_containers.each(function(index, file_container){

      const report_id = $(file_container).data("report_id");
      const dir = "/";
      const interview_question_name = $(file_container).data("value_name");

      // 初期化
      displayUploadFiles(file_container, report_id, interview_question_name);

      $(file_container).dropzone({
        dictDefaultMessage: "",
        maxFilesize: 100,
        maxFiles: 10,
        action: "post",
        headers: {
          'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
        },
        params: {
          report_id,
          dir,
          interview_question_name,
        },
        init: function init() {
          this.on("completemultiple", () => {
          });
          this.on("success", (file, response) => {
              displayUploadFiles($(this.element)[0], report_id, interview_question_name);
              console.log("送信成功");
          });
          this.on("error", () => {
            displayUploadFiles($(this.element)[0], report_id, interview_question_name);
            console.log("送信エラー");
          });
          this.on("queuecomplete", () => {
            this.removeAllFiles();
          });
        },
        url: "/report_files/upload",
      });
    })

    /**
     * コメント送信
     */
    const on_comment_send_button = (event) => {
      const $target = $(event.currentTarget);
      const report_id = $target.data('report_id');
      const interview_id = $target.data('interview_id');
      const question_id = $target.data('question_id');
      const value_name = $target.data("value_name");
      const $textarea = $("#comments_" + value_name);
      const comment = $textarea.val();
      $textarea.val('');
  
      if (comment === "") {
        return;
      }
  
      $.ajax({
        type: "POST",
        dataType: "json",
        url: `/reports/${report_id}/interview_comments/`,
        data: {
          interview_id: interview_id,
          question_id: question_id,
          comment: comment,
        },
        headers: {
          'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
        },
        context: this,
      }).done((data, textStatus, jqXHR) => {
        if(data.status === 200) {
          const comments_ul = $(".interview_comments_" + value_name).children('ul');
          const li_tag = $('<li>');
          if (data.contributor_no === 1) {
            $('<ruby><div class="comments_vanddd_icon"></div><rt>vanddd</rt></ruby><div class="balloon left"><p>' + data.comment + '</p></div>').appendTo(li_tag);
          } else {
            $('<div class="balloon right"><p>' + data.comment + '</p></div><ruby><div class="comments_coustomer_icon"></div><rt>お客様</rt></ruby>').appendTo(li_tag);
          }
          li_tag.appendTo(comments_ul);
          console.log('コメント送信');
        } else {
          console.log('コメント失敗');
        }
      })    
    }

    /**
     * 質問リクエスト確認フラグ設定送信
     * @param {*} event 
     */
    const on_confirm_button = (event) => {
      const $target = $(event.currentTarget);
      const report_id = $target.data('report_id');
      const interview_id = $target.data('interview_id');
      const question_id = $target.data('question_id');
      const value_name = $target.data("value_name");
      const $check_confirm = $("#check_confirm_" + value_name);
      const confirm_flag = $check_confirm.prop("checked");
  
      $.ajax({
        type: "PUT",
        dataType: "json",
        url: `/reports/${report_id}/interview_comments/${interview_id}`,
        data: {
          question_id: question_id,
          confirm_flag: confirm_flag,
        },
        headers: {
          'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
        },
        context: this,
      }).done((data, textStatus, jqXHR) => {
        if(data.status === 200) {
          const $confirm_question = $("#" + value_name);
          if(data.confirm_flag === true){
            $confirm_question.addClass('question_condetion_red');
          }else{
            $confirm_question.removeClass('question_condetion_red');
          }
          console.log('確認フラグ更新成功');
        } else {
          console.log('確認フラグ更新失敗');
        }
      })    
    }
  
    /**
     * 質問リクエスト関連イベント設定
     */
    $('answer-edit-contents').each((index, element) => {
      const value_name = $(element).data("value_name");
      const $send_button = $(".comments_send_" + value_name);
      const $confirm_button = $(".comments_confirm_" + value_name);

      $send_button.on('click', (event) => {
        on_comment_send_button(event);
      })

      $confirm_button.on('click', (event) => {
        on_confirm_button(event);
      })
    })
  }

  /**
   * 回答画面の表示
   */
  setViewAnswerEdit() {
    $("#result_edit .answer-edit-contents").show();
    $("#result_edit .answer-result-contents").hide();
    $("#result_edit #trigger--answer_edit_button").css(
      "background-color",
      "white"
    );
    $("#result_edit #trigger--answer_result_button").css(
      "background-color",
      "#f4f6f8"
    );
  }

  /**
   * 解答画面の表示
   */
  setViewAnswerResult() {
    $("#result_edit .answer-edit-contents").hide();
    $("#result_edit .answer-result-contents").show();
    $("#result_edit #trigger--answer_edit_button").css(
      "background-color",
      "#f4f6f8"
    );
    $("#result_edit #trigger--answer_result_button").css(
      "background-color",
      "white"
    );
  }

  /**
   * インタビューで表示する質問を設定してください。
   * app/views/interview_questions/_form.html.erb
   */
  interviewQuestionEdit() {
    $(() => {
      let cnt = $(".question_block").length;

      // 質問追加時
      $(document).on("cocoon:after-insert", ".trigger--insert_question", () => {
        cnt += 1;
        $(".event--question_count-render:last").text(cnt);
        $(".question_block:last .answer_type").attr("id", `answer_type_${cnt}`);

        $(".question_block:last .item_block").attr("id", `item_block_${cnt}`);
        $(".question_block:last .next_question").val(cnt + 1);

        const DEFAULT_ROW_SIZE = 1;
        $(".question_block:last .default-row-size")
          .removeClass(`default-row-size__${cnt - 1}`)
          .addClass(`default-row-size__${cnt}`)
          .find(".default-row-size__form-input")
          .val(DEFAULT_ROW_SIZE);
      });

      // 選択肢追加時
      $(document).on(
        "cocoon:after-insert",
        ".trigger--insert_item",
        function () {
          const item_block_id = `#${$(this).parent().attr("id")}`;
          const no = item_block_id.replace("#item_block_", "");
          const text = $(`#answer_type_${no} option:selected`).text();
          if (text === ANSWER_TYPE__CHECK_BOX || text === ANSWER_TYPE__TABLE) {
            $(item_block_id)
              .find(".item_next_question")
              .attr("style", "display:none !important");
          } else {
            $(item_block_id)
              .find(".item_next_question")
              .attr("style", "display:block");
          }
          if (text === ANSWER_TYPE__TABLE) {
            $(item_block_id)
              .find(".item_text_id")
              .attr("style", "display:block");
          } else {
            $(item_block_id)
              .find(".item_text_id")
              .attr("style", "display:none !important");
          }
        }
      );

      // 質問形式変更時
      // UIパーツの表示切り替え
      $(document).on("change", ".trigger-answer_type", function () {
        const answer_type_id = `#${$(this).attr("id")}`;
        const no = answer_type_id.replace("#answer_type_", "");
        const text = $(`${answer_type_id} option:selected`).text();
        const $itemBlock = $(`#item_block_${no}`);
        const $defaultRowSize = $(`.default-row-size.default-row-size__${no}`);

        switch (text) {
          case ANSWER_TYPE__RADIO_BUTTON:
          case ANSWER_TYPE__SELECT_BOX:
            $itemBlock.show();
            $itemBlock.find(".item_next_question").show();
            $itemBlock
              .find(".item_text_id")
              .attr("style", "display:none !important");
            $itemBlock.find("input").prop("disabled", false);
            $defaultRowSize.hide();
            break;
          case ANSWER_TYPE__TEXT_BOX:
            $defaultRowSize.show();
            $itemBlock.hide();
            $itemBlock.find("input").prop("disabled", true);
            break;
          case ANSWER_TYPE__CHECK_BOX:
            $defaultRowSize.hide();
            $itemBlock.show();
            $itemBlock
              .find(".item_next_question")
              .attr("style", "display:none !important");
            $itemBlock
              .find(".item_text_id")
              .attr("style", "display:none !important");
            $itemBlock.find("input").prop("disabled", false);
            $itemBlock.find(".item_next_question").prop("disabled", true);
            break;
          case ANSWER_TYPE__TABLE:
            $defaultRowSize.show();
            $itemBlock.show();
            $itemBlock
              .find(".item_next_question")
              .attr("style", "display:none !important");
            $itemBlock.find("input").prop("disabled", false);
            $itemBlock.find(".item_text_id").show();
            break;
          case ANSWER_TYPE__FILE:
            // TODO T.B.D.
            $defaultRowSize.show();
            $itemBlock.hide();
            $itemBlock.find("input").prop("disabled", true);
            break;
        }
      });

      // 質問並び順変更
      const update_sort_no = (ui) => {
        $(".edit_interview")
          .find(".interview_question_sort_no")
          .each(function (index, element) {
            var count = index + 1;
            $(element).val(count);
          });
      }

      $(document).ready(function () {
        update_sort_no();
        $(".edit_interview").sortable({
          items: ".sortable_block", // 特定の要素のみ対象
          axis: "y", // ドラッグの方向を縦に固定
          opacity: 0.5, // ドラッグ中の透明度
          update: function (event, ui) {
            // ドラッグ完了後のコールバック
            update_sort_no(ui);
          },
        });
      });

      // 選択肢並び順変更
      const update_item_sort_no = () => {
        $(".item_block").each(function (item_index, item) {
          $(item)
            .find(".interview_question_item_sort_no")
            .each(function (index, element) {
              element.value = index + 1;
            });
        });
      }

      $(document).ready(function () {
        update_item_sort_no();
        $(".item_block").sortable({
          items: ".sortable_block", // 特定の要素のみ対象
          axis: "y", // ドラッグの方向を縦に固定
          opacity: 0.5, // ドラッグ中の透明度
          update: function (event, ui) {
            // ドラッグ完了後のコールバック
            update_item_sort_no();
          },
        });
      });
      // サンプル画像アップロード　TODO
      let formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return '0 Bytes';
    
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    
        const i = Math.floor(Math.log(bytes) / Math.log(k));
    
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
      }

      let displayQaSampleFiles = (upload_content, value_name) => {
        $.ajax({
          type: "POST",
          dataType: "json",
          url: `/qa_sample_files/interview_qa_sample_files/`,
          data: {
            value_name,
          },
          headers: {
            'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
          },
          context: upload_content,
        }).done((data, textStatus, jqXHR) => {
          const $question = $(upload_content);
          const count = $question.data("count");
          const question_no = count - 1;
          const value_name_form = "#interview_interview_questions_attributes_" + question_no + "_value_name";
          const value_name = $(value_name_form).val();
          const element = $(".question_" + count + "_upload_files");
          element.empty();
          const upload_status_table = $('<table>').addClass('table table-sm bg-white question_upload_file_results mb-2');
          $('<thead><tr><th>ファイル名</th><th>アップロード日</th><th>ステータス</th><th></th></tr></thead>').appendTo(upload_status_table);
          const upload_status_tbody = $('<tbody>').appendTo(upload_status_table);
          data.files.forEach((file, index) => {
            let tr_tag = $('<tr class="' + value_name + '__' + file[0] + '">').appendTo(upload_status_tbody);
            $('<td>' + file[1] + '</td>').appendTo(tr_tag);
            $('<td>' + file[3].substr(0, 4) + '年' + file[3].substr(5, 2) + '年' + file[3].substr(8, 2) + '日' + '</td>').appendTo(tr_tag);
            let input_tag = $('<td><div class="upload_result success"><div class="cricle"><i class="fa fa-check fs-16 success-icon"></i></div><span style="color: #ffffff;">アップロード成功</span><input class="form-item-file-upload" type="hidden" name="' + value_name + '_' + index + '" id="' + value_name + '_' + index + '"></td></div>').appendTo(tr_tag);
            input_tag.val(file[0]);
            let td_tag = $('<td>');
            let delete_button = $('<a class="upload_file_delete btn btn-danger" data-upload_file_value_name="' + value_name + '" data-file_id="' + file[0] + '" data-file_path="' + file[1] + '">削除</div>').appendTo(td_tag);
            delete_button.on('click', function(element){
              const file_id = $(this).data('file_id');
              const file_path = $(this).data('file_path');
              $.ajax({
                type: "POST",
                url: `/user_files/${file_id}/${file_path}`,
                data: {
                  "id": file_id,
                  "file_path": file_path
                },
                context: this,
                dataType: "json",
                error: () => console.log("error"),
              }).done((data, textStatus, jqXHR) => {
                const upload_file_value_name = $(this).data('upload_file_value_name');
                const file_id = $(this).data('file_id');
                $('.' + upload_file_value_name + '__' + file_id).remove();
                console.log("削除！！");
              });
            })
            $(delete_button).appendTo(tr_tag);
          })
          upload_status_table.appendTo(element);
        });    
      }

      /**
       * Fileアップロード
       */
      const file_containers = $(".user_files__upload_interview_sample_file_container");
      file_containers.each(function(index, file_container){
        const count = $(file_container).data("count");
        const question_no = count - 1;
        const value_name_form = "#interview_interview_questions_attributes_" + question_no + "_value_name";
        const value_name = $(value_name_form).val();
        // 初期化
        displayQaSampleFiles(file_container, value_name);
  
        $(file_container).dropzone({
          dictDefaultMessage: "",
          maxFilesize: 100,
          maxFiles: 10,
          action: "post",
          headers: {
            'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
          },
          params: {
            value_name,
          },
          init: function init() {
            this.on("completemultiple", () => {
            });
            this.on("success", (file, response) => {
              displayQaSampleFiles($(this.element)[0], value_name);
                console.log("送信成功");
            });
            this.on("error", () => {
              displayQaSampleFiles($(this.element)[0], value_name);
              console.log("送信エラー");
            });
            this.on("queuecomplete", () => {
              this.removeAllFiles();
            });
          },
          url: "/qa_sample_files/upload",
        });
      })
    });
  }

  render() {
    return null;
  }
}

export default hot(module)(InterviewBackgroundContainer);
