import {
  ANSWER_TYPE__CHECK_BOX,
  ANSWER_TYPE__RADIO_BUTTON,
  ANSWER_TYPE__TABLE,
  ANSWER_TYPE__TEXT_BOX,
  ANSWER_TYPE__FILE,
} from "../../../../constants";
import { type } from "jquery";

/**
 * JSONを回答
 */
export function answerJson() {
  const answer = {};
  const questions = $(".answer-block");
  questions.each(function(i, question) {
    const $question = $(question);
    const questionType = $question.data("type");

    switch (questionType) {
      case ANSWER_TYPE__RADIO_BUTTON:
        if ($question.find("input:checked").val()) {
          const question_value_name = $question.data("value_name");
          const item_item = $question.find("input:checked").val();
          answer[question_value_name] = item_item;
        }
        break;
      case ANSWER_TYPE__CHECK_BOX:
        if ($question.find("input:checked").val()) {
          const question_value_name = $question.data("value_name");
          const check_hash = {};
          $question.find("input:checkbox").map(
            function () {
              check_hash[$(this).val()] = $(this).data("item_item");
            }
          );
          answer[question_value_name] =　check_hash;
        }
        break;
      case ANSWER_TYPE__TEXT_BOX:
        if ("" !== $question.find("textarea").val()) {
          const question_value_name = $question.data("value_name");
          const text = $question.find("textarea").val();
          answer[question_value_name] = text;
        }
        break;
      case ANSWER_TYPE__TABLE:
        const question_value_name = $question.data("value_name");
        const itemsId = $question.find("table")
          .find("th")
          .map(function () {
            return $(this).data("item_item");
          })
          .get();
        let row_count = 1;
        const table_hash = {};
        $question.find("table").children("tbody").children("tr").map(
          function () {
            let send_flag = false;
            const row_hash = {};

            $(this)
              .find(".form-item-input")
              .map(function () {
                if ($(this).val()) {
                  send_flag = true;
                }
              });
            if (send_flag) {
              $(this)
                .find(".form-item-input")
                .map(function (n) {
                  row_hash[itemsId[n]] = $(this).val();
                });
              table_hash[row_count] = row_hash;
              row_count += 1;
            }
          }
        );
        if ("1" in table_hash) {
          answer[question_value_name] = table_hash;
        }
        break;
      case ANSWER_TYPE__FILE:
        const files = $question.find(".question_upload_files input");
        if (files != typeof undefined) {
          const question_value_name = $question.data("value_name");
          let file_names = [];
          files.each((index, file) => {
            file_names[index] = file.value;
          });

          answer[question_value_name] = file_names;
        }
        break;
      default:
        break;
    }
  })
  return answer;
}
