import {
  answerJson,
  setAnswer,
} from "./editFunctions";
import { tableRowWithLen } from "./elements";
import autosize from "autosize";
import "dropzone";


// noinspection JSUnusedLocalSymbols
function setPrevArr() {
  // interview_answerにprevArrを入れるカラムを用意する
  // answer登録時にprevArrも登録する
  // 上記は登録時に行うところ
  // ===================================
  // prevArrのカラムを取得してprevArrにセットする
}

function setView() {
  // prevArrにセットされている最後の質問ページを表示する
}

/**
 * インタビュー回答画面
 */
export function interviewAnswerEdit() {
  const prevArr = [];

  /**
   * 初期表示QA以外を隠す
   * @param {*} chain_questions_name 
   */
  const hide_all_next_question = (chain_questions_name) => {
    const chain_questions = $(chain_questions_name);
    chain_questions.each((index, chain_question) => {

      const base_question_name = $(chain_question).data("base_question_name");
      const same_base_questions = $(".base_question_" + base_question_name);
      same_base_questions.hide();
      same_base_questions.each((index, question) => {
        const radio_box = $(question).find(".form-item-radio-box");
        if (typeof radio_box !== "undefined"){
          const base_question_value_name = radio_box.data("base_question_value_name")
          if (typeof base_question_value_name !== "undefined"){
            const next_question = ".base_question_" + base_question_value_name;
            hide_all_next_question(next_question);
          }
        }
      })
    })
  }


  const display_next_question = (next_question_value_name) => {
    const next_question = ".question_box_" + next_question_value_name;
    const next_question_box = $(next_question);

    const radio_box = next_question_box.find(".form-item-radio-box");
    if(radio_box.prop('checked')){
      const value_name = radio_box.data("next_question_value_name");
      display_next_question(value_name);
    }

    next_question_box.fadeIn("fast").effect("highlight", { color: "#ffd769" }, 1200);
    const chain_next_question = next_question_box.data("next_question_value_name");
    if ((typeof chain_next_question !== "undefined") && chain_next_question != "") {
      display_next_question(chain_next_question);
    }
  }

  /**
   * clickイベントを追加(ラジオボタンの初期値挿入前にイベントを設定する必要があるためsetAnswer()より手前に定義)
   * ラジオボタン選択時質問詳細表示/非表示切替
   */
  $(".form-item-radio-box").on("click", (event) => {
    const element = $(event.currentTarget);
    const next_question_value_name = element.data("next_question_value_name");
    const base_question_value_name = element.data("base_question_value_name");
    if (typeof base_question_value_name !== "undefined"){
      const sibling_question = ".base_question_" + base_question_value_name;
      hide_all_next_question(sibling_question);
    }
    display_next_question(next_question_value_name);
  });

  // 既存の回答をセットする
  setAnswer();
  setView();

  /**
   * addイベントを追加
   * テーブル 行の追加
   */
  $(document).on("click", ".trigger--add_row", (event) => {
    const element = $(event.target);
    const value_name = $(element).data("value_name");
    const len =
      $("#interview_answer_edit .answer-block:visible tbody").children()
        .length + 1;
    const items = $(element).data("items");
    $(`#${value_name}`).find("table").children("tbody").append(
      "<tr/>"
    );
    $(items).each((i, item) => {
      const element = $(
        tableRowWithLen({
          valueName: value_name,
          items: items,
          i,
          len,
        })
      );
      $(`#${value_name}`).find("table").children("tbody").find("tr:last").append(element);
      setTimeout(() => {
        autosize(element.find(".form-item-input"));
      }, 0);
    })
  });

  /**
   * clickイベントを追加
   * テーブル 行の削除
   */
  $(document).on("click", ".trigger--remove_row", (event) => {
    const element = $(event.target);
    const value_name = $(element).data("value_name");
    const len = $(`#${value_name}`).find("table").children("tbody").children().length;
    if (len > 1) {
      const rt = confirm("最後の行を削除してよろしいですか？");
      if (rt === true) {
        $(`#${value_name}`).find("table").children("tbody").find("tr:last").remove();
      }
    }
  });

  const on_comment_send_button = (event) => {
    const $target = $(event.currentTarget);
    const report_id = $target.data('report_id');
    const interview_id = $target.data('interview_id');
    const question_id = $target.data('question_id');
    const value_name = $target.data("value_name");
    const $textarea = $("#comments_" + value_name);
    const comment = $textarea.val();
    $textarea.val('');

    if (comment === "") {
      return;
    }

    $.ajax({
      type: "POST",
      dataType: "json",
      url: `/reports/${report_id}/interview_comments/`,
      data: {
        interview_id: interview_id,
        question_id: question_id,
        comment: comment,
      },
      headers: {
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      },
      context: this,
    }).done((data, textStatus, jqXHR) => {
      if(data.status === 200) {
        const comments_ul = $(".interview_comments_" + value_name).children('ul');
        const li_tag = $('<li>');
        if (data.contributor_no === 1) {
          $('<ruby><div class="comments_vanddd_icon"></div><rt>vanddd</rt></ruby><div class="balloon left"><p>' + data.comment + '</p></div>').appendTo(li_tag);
        } else {
          $('<div class="balloon right"><p>' + data.comment + '</p></div><ruby><div class="comments_coustomer_icon"></div><rt>お客様</rt></ruby>').appendTo(li_tag);
        }
        li_tag.appendTo(comments_ul);
        console.log('コメント送信');
      } else {
        console.log('コメント失敗');
      }
    })    
  }

  const on_confirm_button = (event) => {
    const $target = $(event.currentTarget);
    const report_id = $target.data('report_id');
    const interview_id = $target.data('interview_id');
    const question_id = $target.data('question_id');
    const value_name = $target.data("value_name");
    const $check_confirm = $("#check_confirm_" + value_name);
    const confirm_flag = $check_confirm.prop("checked");

    $.ajax({
      type: "PUT",
      dataType: "json",
      url: `/reports/${report_id}/interview_comments/${interview_id}`,
      data: {
        question_id: question_id,
        confirm_flag: confirm_flag,
      },
      headers: {
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      },
      context: this,
    }).done((data, textStatus, jqXHR) => {
      if(data.status === 200) {
        const $confirm_question = $("#" + value_name);
        if(data.confirm_flag === true){
          $confirm_question.addClass('question_condetion_red');
        }else{
          $confirm_question.removeClass('question_condetion_red');
        }
        console.log('確認フラグ更新成功');
      } else {
        console.log('確認フラグ更新失敗');
      }
    })    
  }

  $('.answer-block').each((index, element) => {
    const value_name = $(element).data("value_name");
    const $send_button = $(".comments_send_" + value_name);
    const $confirm_button = $(".comments_confirm_" + value_name);
    
    $send_button.on('click', (event) => {
      on_comment_send_button(event);
    })

    $confirm_button.on('click', (event) => {
      on_confirm_button(event);
    })
  })

  /**
   * ファイルアップロード情報更新
   * @param {*} upload_content 
   * @param {*} report_id 
   * @param {*} interview_question_name 
   */
  const displayUploadFiles = (upload_content, report_id, interview_question_name) => {
    // TODO 今はアップロード先との接続ができないため、エラー時にファイル名を出力するよう仮に設定
    $.ajax({
      type: "POST",
      dataType: "json",
      url: `/report_files/interview_question_files/`,
      data: {
        report_id,
        interview_question_name,
      },
      headers: {
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      },
      context: upload_content,
    }).done((data, textStatus, jqXHR) => {
      const $question = $(upload_content);
      const value_name = $question.data("value_name");
      const element = $(".question_" + value_name + "_upload_files");
      element.empty();
      const upload_status_table = $('<table>').addClass('table table-sm bg-white question_upload_file_results mb-2');
      $('<thead><tr><th>ファイル名</th><th>アップロード日</th><th>ステータス</th><th></th></tr></thead>').appendTo(upload_status_table);
      const upload_status_tbody = $('<tbody>').appendTo(upload_status_table);
      data.files.forEach((file, index) => {
        let tr_tag = $('<tr id="' + value_name + '__' + file[0] + '">').appendTo(upload_status_tbody);
        $('<td>' + file[1] + '</td>').appendTo(tr_tag);
        $('<td>' + file[3].substr(0, 4) + '年' + file[3].substr(5, 2) + '年' + file[3].substr(8, 2) + '日' + '</td>').appendTo(tr_tag);
        let input_tag = $('<td><div class="upload_result success"><div class="cricle"><i class="fa fa-check fs-16 success-icon"></i></div><span style="color: #ffffff;">アップロード成功</span><input class="form-item-file-upload" type="hidden" name="' + value_name + '_' + index + '" id="' + value_name + '_' + index + '"></td></div>').appendTo(tr_tag);
        input_tag.val(file[0]);
        let td_tag = $('<td>');
        let delete_button = $('<a class="upload_file_delete btn btn-danger" data-upload_file_value_name="' + value_name + '" data-file_id="' + file[0] + '" data-file_path="' + file[1] + '">削除</div>').appendTo(td_tag);
        delete_button.on('click', (element) => {
          const target = $(element.currentTarget);
          const file_id = $(target).data('file_id');
          const file_path = $(target).data('file_path');
          $.ajax({
            type: "POST",
            url: `/user_files/${file_id}/${file_path}`,
            data: {
              "id": file_id,
              "file_path": file_path
            },
            context: target,
            dataType: "json",
            error: () => console.log("error"),
          }).done((data, textStatus, jqXHR) => {
            const upload_file_value_name = $(target).data('upload_file_value_name');
            const file_id = $(target).data('file_id');
            $('#' + upload_file_value_name + '__' + file_id).remove();
            console.log("削除！！");
          });
        })
        $(delete_button).appendTo(tr_tag);
      })
      upload_status_table.appendTo(element);
    });    
  }

  /**
   * Fileアップロード
   */
  const file_containers = $(".user_files__upload_interview_file_container");
  file_containers.each((index, file_container) => {

    const report_id = $(file_container).data("report_id");
    const dir = "/";
    const interview_question_name = $(file_container).data("value_name");

    // 初期化
    displayUploadFiles(file_container, report_id, interview_question_name);

    $(file_container).dropzone({
      dictDefaultMessage: "",
      maxFilesize: 100,
      maxFiles: 10,
      action: "post",
      headers: {
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      },
      params: {
        report_id,
        dir,
        interview_question_name,
      },
      init: function init() {
        this.on("completemultiple", () => {
        });
        this.on("success", (file, response) => {
            // TODO 今はアップロード先との接続ができないため、エラー時にファイル名を出力するよう仮に設定
            displayUploadFiles($(this.element)[0], report_id, interview_question_name);
            // TODO 仮に設定ここまで
            console.log("送信成功");
        });
        this.on("error", () => {
          displayUploadFiles($(this.element)[0], report_id, interview_question_name);
          console.log("送信エラー");
        });
        this.on("queuecomplete", () => {
          this.removeAllFiles();
        });
      },
      url: "/report_files/upload",
    });
  })

  /**
   * 代理回答を依頼し一時保存
   */
  var remodal = $('[data-remodal-id=modal_reply_instead]').remodal();
  $(document).on('confirmation', remodal, (event) => {
    const element = $(event.data.$modal[0]);
    const group_id = element.data("group_id");
    const report_id = element.data("report_id");
    const interview_id = element.data("interview_id");
    const answer_id = element.data("answer_id");
    const reply_instead_email = $("#reply_instead_email").val();
    const answer_answer = answerJson();
    // FIXME: POSTに修正する
    $.ajax({
      type: "POST",
      url: `/reports/${report_id}/interview_answers/${answer_id}/temporary_saving`,
      data: {
        group_id,
        report_id,
        interview_id,
        answer_id,
        reply_instead_email,
        answer_answer,
      },
      dataType: "json",
      success: (_response) => console.log("success"),
      error: (_response) => console.log("error"),
    });
 });
}
