// noinspection OverlyNestedFunctionJS
import {
  ANSWER_TYPE__CHECK_BOX,
  ANSWER_TYPE__RADIO_BUTTON,
  ANSWER_TYPE__SELECT_BOX,
  ANSWER_TYPE__TABLE,
  ANSWER_TYPE__TEXT_BOX,
  ANSWER_TYPE__FILE,
} from "../../../../constants";
import { tableRow } from "../elements";
import autosize from "autosize";

// noinspection OverlyNestedFunctionJS
/**
 * 解答をセットする
 */
export function setAnswer() {
  const questions = $(".answer-block");
  questions.each(function(i, question) {
    const answers = $("#interview_answer_edit").data("answer_answer");
    const $question = $(question);
    const questionValueName = $question.data("value_name");
    const answerType = $question.data("type");

    if (!answers[questionValueName]) {
      return true;
    }

    const $interviewAnswerEdit = $(
      `#interview_answer_edit [name="${questionValueName}"][value="${Object.values(answers[questionValueName]["result"])}"]`
    );

    let valueName;
    let items;
    switch (answerType) {
      case ANSWER_TYPE__RADIO_BUTTON:
        // ラジオボタン
        $interviewAnswerEdit.attr("checked", true);
        $interviewAnswerEdit.prop('checked',false);
        $interviewAnswerEdit.prop('checked',true).trigger('click');
        break;
      case ANSWER_TYPE__SELECT_BOX:
        // セレクトボックス
        $interviewAnswerEdit.attr("selected", true);
        break;
      case ANSWER_TYPE__CHECK_BOX:
        // チェックボックス
        items = Object.keys(answers[questionValueName]["result"]);
        $.each(items, (index) =>
          $(question).find(`#${items[index]}`).attr("checked", true)
        );
        break;
      case ANSWER_TYPE__TEXT_BOX:
        // テキストボックス
        const item = Object.keys(answers[questionValueName]["result"])[0];
        const itemItem = answers[questionValueName]["result"][item];
        $question.find(`textarea`).val(itemItem);
        setTimeout(() => {
          autosize($(".form-item-input"));
        }, 0);
        break;
      case ANSWER_TYPE__TABLE:
        // テーブル
        const rows = Object.keys(answers[questionValueName]["result"]);
        if (Object.keys(rows).length > 0) {
          const items = Object.keys(answers[questionValueName]["result"]["1"]);
          $.each(rows, (index, _) => {
            if (index <= 0) {
            } else {
              $question.find("table").children("tbody").append("<tr/>");
            }
            $.each(items, (n, item) => {
              const itemItem =
                answers[questionValueName]["result"][index + 1][item];
              $question.find(`.form-item-input[name='${questionValueName}[${
                index + 1
              }][${item}]']`
              ).val(itemItem);
            });
          });
        }
        break;
      case ANSWER_TYPE__FILE:
        // ファイル TODO
        const element = $question.find(".question_upload_files");
        const value_name = $question.data("value_name");
        const fileNames = answers[questionValueName]["result"]["-1"];
        if(typeof fileNames !== "undefined"){
          fileNames.forEach((file, index) => {
            let input_tag = $('<label>選択中のファイル：' + file + '</label><input class="question_upload_file" type="hidden" name="' + value_name + '[' + index + ']" id=""><br>').appendTo(element);
            input_tag.val(file);
          });
        }
        break;
      default:
        throw new Error("Unknown answer type");
    }
  })
}
