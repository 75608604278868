const tableRowClass = "form-control input-text form-item-input";

// ▼ Ruby側も編集が必要
// app/views/interview_answers/_question__table.html.erb:18

/**
 * テーブル列の要素
 */
export function tableRow({ valueName, index, itemNames, j }) {
  console.log(itemNames);
  return `<td><textarea type="text" rows="1" class="${tableRowClass}" name="${valueName}[${
    index + 1
  }][${itemNames[j]}]"></textarea></td>`;
}

/**
 * テーブル列の要素
 */
export function tableRowWithLen({ valueName, len, items, i }) {
  return `<td><textarea class="${tableRowClass}" rows="1" type="text" name="${valueName}[${len}][${items[i]}]"></textarea></td>`;
}
