export const OCR_RESULT_CATEGORY = {
  BALANCE_SHEET: 1,
  PROFIT_AND_LOSS_STATEMENT: 2,
};

export const DEVELOPMENT = "development";

export const ANSWER_TYPE__RADIO_BUTTON = "ラジオボタン";
export const ANSWER_TYPE__TEXT_BOX = "テキストボックス";
export const ANSWER_TYPE__SELECT_BOX = "セレクトボックス";
export const ANSWER_TYPE__CHECK_BOX = "チェックボックス";
export const ANSWER_TYPE__TABLE = "表";
export const ANSWER_TYPE__FILE = "ファイル";

