import React from "react";
import { hot } from 'react-hot-loader'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import orange from '@material-ui/core/colors/orange'

const OcrSpreadSheetList = props => {
  const spreadsheets = props.referenceAreaInput.map(item => { return item.spreadsheet })
  const fs_images = props.referenceAreaInput.map(item => { return item.fs_image })

  return (
    <React.Fragment>
      <div className="mt-3 mb-1 ml-3 fs-11">※以下、クリックするとコピーできます。</div>
      <div className="scroll-area">
        {spreadsheets.map((spreadsheet, index) =>
          <OcrSpreadSheet
            key={index}
            spreadSheet={spreadsheet}
            file_name={fs_images[index].file_name}
          />
        )}
      </div>
    </React.Fragment>
  )
}

export default hot(module)(OcrSpreadSheetList);


const OcrSpreadSheet = props => {

  return(
    <>
    <table className="text-center" style={{ width: "100%", backgroundColor: "white" }}>
        <thead><tr><td><div className="report-title ml-3 mt-3">{props.file_name}</div></td></tr>
      </thead>
    </table>
    <table className="mx-0 table table-sm table-bordered px-0" style={{ width: "100%", backgroundColor: "white" }}>
      <Tbody {...props} />
    </table>
    </>
  );
}

const Tbody = props => {
  const r = JSON.parse(props.spreadSheet).map((row, index) => 
    <Row key={index} row={row}/>
  )
  return (<tbody>{r}</tbody>)
}

const Row = props => {
  const row = props.row.map((cell, index) => <Cell key={index} cell={cell} />);
  if (row.length > 0) {
    return (<tr>{row}</tr>);
  } else {
    return false;
  }
}

import Fade from "@material-ui/core/Fade";
const Cell = props => {
  const [checked, setChecked] = React.useState(false);
  const copiedText = props.cell.replace(/,|【|】/g, "");

  const onClick = (e) => {
    setChecked(prev => !prev);
    setTimeout(closeBox, 2000);
    e.preventDefault()
  };

  const closeBox = () => {
    setChecked(prev => !prev);
  };

  const onCopy = (c) => {
    console.log("Copied", c)
  };

  const displayStyle = checked ? { display: "block", color: orange[600] } : { display: "none", }

  return (
    <td>
      {props.cell &&
        <>
          <CopyToClipboard
            onCopy={(c) => onCopy(c)}
            options={{ message: 'Whoa!' }}
            text={copiedText}>
            <button onClick={(e) => onClick(e)}>
              {copiedText}
            </button>
          </CopyToClipboard>
          <Fade in={checked}>
            <div className="" style={displayStyle}>コピーされました</div>
          </Fade>
        </>
      }
    </td>
  )
}
