import { catchError, exhaustMap, map, filter } from "rxjs/operators";
import { combineEpics } from "redux-observable";
import { of } from "rxjs";
import {
  FINANCIAL_STATEMENT_IMAGE_EXEC_OCR_CLICK,
  FINANCIAL_STATEMENT_IMAGE_EXEC_OCR_REQUEST,
  FINANCIAL_STATEMENT_IMAGE_GET_STATUS_REQUEST,
  FINANCIAL_STATEMENT_IMAGE_GET_RESULT_REQUEST,
  FINANCIAL_STATEMENT_IMAGE_MODAL_OPEN,
  FINANCIAL_STATEMENT_IMAGE_REANALYZE_CLICK,
  FINANCIAL_STATEMENT_IMAGE_REANALYZE_REQUEST,
  FinancialStatementImagesMainAction,
} from "../../actions/financialActions/financialStatementImages/Main";
import { FinancialStatementImageApi } from "../../apis/FinancialStatementImageApi";

// ボタンを押す => 解析開始 =====================================================================================================-
const financialStatementImageExecOcrClickEpic = (action$) =>
  action$.pipe(
    filter(
      (action) => action.type === FINANCIAL_STATEMENT_IMAGE_EXEC_OCR_CLICK
    ),
    map((action) => {
      const { fs_image_id, report_id, fs_images } = action;
      return FinancialStatementImagesMainAction.financialStatementImageExecOcrRequest(
        { fs_image_id, report_id, fs_images }
      );
    })
  );

const financialStatementImageExecOcrRequestEpic = (action$) =>
  action$.pipe(
    filter(
      (action) => action.type === FINANCIAL_STATEMENT_IMAGE_EXEC_OCR_REQUEST
    ),
    exhaustMap((action) => {
      const { fs_image_id, report_id, fs_images } = action;
      return FinancialStatementImageApi.financialStatementImagesExecOcr({
        fs_image_id,
        report_id,
      }).pipe(
        map((res) => convertResponseForExecOcr(res, fs_images)),
        map((res_converted) =>
          FinancialStatementImagesMainAction.financialStatementImageExecOcrSuccess(
            res_converted
          )
        ),
        catchError((error) =>
          of(
            FinancialStatementImagesMainAction.financialStatementImageExecOcrFailure(
              { fs_image_id, report_id, fs_images, error }
            )
          )
        )
      );
    })
  );

// ステータス取得 ==================================================================================
const financialStatementImageGetStatusRequestEpic = (action$) =>
  action$.pipe(
    filter(
      (action) => action.type === FINANCIAL_STATEMENT_IMAGE_GET_STATUS_REQUEST
    ),
    exhaustMap((action) => {
      const { fs_image_id, report_id, fs_images } = action;
      return FinancialStatementImageApi.financialStatementImagesGetStatus({
        fs_image_id,
        report_id,
      }).pipe(
        map((res) => convertResponseForGetStatus(res, fs_images)),
        map((res_converted) =>
          FinancialStatementImagesMainAction.financialStatementImageGetStatusSuccess(
            res_converted
          )
        ),
        catchError((error) =>
          of(
            FinancialStatementImagesMainAction.financialStatementImageGetStatusFailure(
              { fs_image_id, report_id, fs_images, error }
            )
          )
        )
      );
    })
  );
// モーダルを開く => 結果を取得 =========================================================================
const financialStatementImageModalOpenEpic = (action$) =>
  action$.pipe(
    filter((action) => action.type === FINANCIAL_STATEMENT_IMAGE_MODAL_OPEN),
    map((action) => {
      const { fs_image } = action;
      return FinancialStatementImagesMainAction.financialStatementImageGetResultRequest(
        { fs_image }
      );
    })
  );
const financialStatementImageGetResultRequestEpic = (action$) =>
  action$.pipe(
    filter(
      (action) => action.type === FINANCIAL_STATEMENT_IMAGE_GET_RESULT_REQUEST
    ),
    exhaustMap((action) => {
      const { fs_image_id, report_id } = action.fs_image;
      return FinancialStatementImageApi.financialStatementImagesGetResult({
        fs_image_id,
        report_id,
      }).pipe(
        map((res) =>
          FinancialStatementImagesMainAction.financialStatementImageGetResultSuccess(
            { res }
          )
        ),
        catchError((error) => {
          return of(
            FinancialStatementImagesMainAction.financialStatementImageGetResultFailure(
              error
            )
          );
        })
      );
    })
  );

// ボタンを押す => 再解析 =======================================================================================
const financialStatementImageReanalyzeClickEpic = (action$) =>
  action$.pipe(
    filter(
      (action) => action.type === FINANCIAL_STATEMENT_IMAGE_REANALYZE_CLICK
    ),
    map((action) => {
      const { fs_image, fs_images } = action;
      return FinancialStatementImagesMainAction.financialStatementImageReanalyzeRequest(
        { fs_image }
      );
    })
  );

const financialStatementImageReanalyzeRequestEpic = (action$) =>
  action$.pipe(
    filter(
      (action) => action.type === FINANCIAL_STATEMENT_IMAGE_REANALYZE_REQUEST
    ),
    exhaustMap((action) => {
      const { fs_image_id, report_id } = action.fs_image;
      return FinancialStatementImageApi.financialStatementImagesReanalyze({
        fs_image_id,
        report_id,
      }).pipe(
        map((res) =>
          FinancialStatementImagesMainAction.financialStatementImageReanalyzeSuccess(
            { res }
          )
        ),
        catchError((error) =>
          of(
            FinancialStatementImagesMainAction.financialStatementImageReanalyzeFailure(
              { error }
            )
          )
        )
      );
    })
  );

const convertResponseForExecOcr = (res, fs_images) => {
  const data = res.data.fs_image_component_input;
  const ocrLimitedCount = res.data.ocr_limited_count;
  const nextImages = fs_images.map((fs_image, index) => {
    if (fs_image.fs_image_id === data.fs_image_id) {
      return data;
    } else {
      return fs_image;
    }
  });
  return {
    dataChanged: data,
    ocrLimitedCount,
    prevImages: fs_images,
    fs_images: nextImages,
  };
};

const convertResponseForGetStatus = (res, fs_images) => {
  const data = res.data.fs_image_component_input;
  const nextImages = fs_images.map((fs_image, index) => {
    if (fs_image.fs_image_id === data.fs_image_id) {
      return data;
    } else {
      return fs_image;
    }
  });
  return {
    dataChanged: data,
    prevImages: fs_images,
    fs_images: nextImages,
  };
};

export const financialStatementImageEpic = combineEpics(
  financialStatementImageExecOcrClickEpic,
  financialStatementImageExecOcrRequestEpic,
  financialStatementImageGetStatusRequestEpic,
  financialStatementImageModalOpenEpic,
  financialStatementImageGetResultRequestEpic,
  financialStatementImageReanalyzeClickEpic,
  financialStatementImageReanalyzeRequestEpic
);
